
$main-color:                  #3e2f3f !default;
$blue:                        $main-color !default;
$main-color-dark:             darken($main-color, 30%);
$main-black:                  #000000 !default;

$theme-colors:                (
                       "main-color": $main-color,
                       "main-black": $main-black,
               );

$context-menu-icon-font-path: "../fonts/contex-menu/";

$custom-file-text: (
                           fr: "Parcourir",
                   );
