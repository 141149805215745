section {
  &#login_index {
    height:           100%;
    min-height:       500px;
    display:          flex;
    flex-direction:   row;
    justify-content:  center;
    align-items:      center;

    .grey_card {
      height:              400px;
      padding:             40px;
      border-radius:       3px;
      border:              1px solid rgba(#666, 0.1);
      box-shadow:          rgba(#666, 0.2) 0 5px 5px 0;
      transition-duration: 0.1s;
      background-color:    rgba(white, 1);
      display:             flex;
      justify-content:     space-between;
      flex-direction:      column;
      align-items:         center;

      &:hover {
        box-shadow: rgba(#666, 0.4) 0 5px 15px 0;
        border:     1px solid rgba(#666, 0.2);
      }
    }

    .cadre_main {
      img {
        height:        180px;
        margin-bottom: 40px;
      }
    }
  }
}