div#content {
  header {
    nav.navbar {
      ul.navbar-nav {
        li.nav-item.dropdown {
          .dropdown-menu {
            a.dropdown-item {
              &.employee {
                line-height: 1.15;

                .small {
                  font-size: 0.65rem;
                }

                &.selected {
                  border-left:      5px solid rgba($main-color, 0.8);
                  padding-left:     14px;
                }

                &.disabled {
                  color: rgba($main-color, 0.8);
                }
              }
            }
          }
        }
      }
    }
  }
}