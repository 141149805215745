//@import "../../../../node_modules/jquery-ui/themes/base/all";

#searchparams {
  .collection-line {
    display:         flex;
    flex-direction:  row;
    align-items:     center;
    justify-content: space-between;
    //overflow:        hidden;

    //background-color: rgba(#0f5132, 0.1);

    margin-bottom:   5px;
    padding-right:   5px;


    .form-group {
      margin: 0 0 0 5px;

      .select2 {
        width: auto !important;
      }

      &.logic_operator {

        select {
          width: 110px;
        }
      }

      &.field {
        flex-grow: 5;
      }

      &.operator {

        select {
          width: 160px;
        }
      }

      &.value {

        input {
          width: 150px;
        }
      }
    }


    .fa-grip-lines {
      cursor: grab;
    }

    .remove-collection {
      margin-left: 10px;
    }
  }
}

.btn-collapse {
  i {
    transition: .2s all ease-in-out;
  }
}
